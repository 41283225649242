import React from "react"
import styled from "styled-components"

const FooterWrapper = styled.footer`
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  padding: 100px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #0e1315;
  border-top: 3px solid #20212a;
`

const InnerContainer = styled.div`
  width: 800px;
  display: flex;
  justify-content: space-between;

  .left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .site-title {
      font-family: "Merienda", cursive;
      font-size: 28px;
      font-weight: normal;
      font-style: italic;
      color: #fff;
    }
  }

  .link-container {
    list-style: none;
    .link {
      margin-bottom: 3px;
      height: 39px;
      a {
        font-size: 20px;
        color: #fff;
        text-decoration: none;
        font-style: italic;
        padding-bottom: 3px;
        border-bottom: 1px solid #45636f;
        border-bottom: 2px solid #20212a;
        &:hover {
          color: #abc2cb;
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`

function Footer() {
  return (
    <FooterWrapper>
      <InnerContainer>
        <div className="left-side">
          <h2 className="site-title">© Asia-Teach</h2>
        </div>
        <ul className="link-container">
          <li className="link">
            <a href="https://asia-teach.com">Home</a>
          </li>
          <li className="link">
            <a href="https://www.asia-teach.com/contact-us">Contact Us</a>
          </li>
          <li className="link">
            <a href="https://www.asia-teach.com/post-job/purchase-jobs">
              Post Job
            </a>
          </li>
          <li className="link">
            <a href="https://www.asia-teach.com/terms-of-service">
              Terms Of Service
            </a>
          </li>
          <li className="link">
            <a
              href="https://twitter.com/TheAsiaTeach"
              rel="noopener noreferrer"
              target="_blank"
            >
              Twitter
            </a>
          </li>
        </ul>
      </InnerContainer>
    </FooterWrapper>
  )
}

export default Footer
