import React from "react"
// import { createGlobalStyle } from "styled-components"
import { useStaticQuery, graphql, PageProps } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import "normalize.css/normalize.css"

interface DataProps {
  children: React.ReactNode
}

// * Not sure if PageProps is necessary
const Layout: React.FC<DataProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>

      <Footer />
    </>
  )
}

export default Layout
