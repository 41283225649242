import { Link } from "gatsby"
import styled from "styled-components"
import React from "react"

import { BiNews } from "react-icons/bi"
import { FaGlobeAsia } from "react-icons/fa"
import { GiTeacher } from "react-icons/gi"

import Logo from "../images/logo.png"

interface DataProps {
  siteTitle: string
}

const HeaderWrapper = styled.header`
  position: fixed;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 55px;
  z-index: 150;
  background: #fff;
  border-bottom: 1px solid #dae0e6;
  padding: 0 25px;
  @media only screen and (max-width: 700px) {
    padding: 0px;
  }
`

const WidthConstraint = styled.div`
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
`

const HeaderLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
`

const LogoContainer = styled.div`
  width: 55px;
  height: 44px;

  img {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 500px) {
    display: none;
  }
`

const LogoText = styled.h1`
  font-size: 20px;
  font-family: "Merienda", cursive;
  font-size: 28px;
  font-weight: normal;
  font-style: italic;
  padding: 0px 0px 0px 20px;
  margin: 0px;
  color: #000;

  @media only screen and (max-width: 700px) {
    font-size: 20px;
    padding: 0px 0px 0px 10px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`

const Nav = styled.nav`
  ul {
    display: flex;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    li {
      height: 100%;
      padding: 0px;
      margin: 0px;
      a {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 1px 4px;
        padding: 4px 6px;
        cursor: pointer;
        color: #000;
        font-size: 18px;
        text-decoration: none;

        .text {
          padding-left: 7px;
          font-style: italic;
          font-size: 16px;
        }

        &:hover {
          background: #dcdcdc;
          border-radius: 3px;
        }

        &.selected {
          font-weight: bold;
        }
        svg {
          font-size: 24px;
        }
      }
    }
  }
`

const Header: React.FC<DataProps> = ({ siteTitle }) => (
  <HeaderWrapper>
    <WidthConstraint>
      <HeaderLink href="https://asia-teach.com" className="header-link">
        <LogoContainer>
          <img src={Logo} alt="Logo" />
        </LogoContainer>
        <LogoText className="logo-text">Asia-Teach</LogoText>
      </HeaderLink>

      <Nav>
        <ul>
          <li>
            <Link to="/" className="selected">
              <BiNews />
              <span className="text">Blog</span>
            </Link>
          </li>
          <li>
            <a href="https://asia-teach.com">
              <FaGlobeAsia />
              <span className="text">Countries</span>
            </a>
          </li>

          <li>
            <a href="https://www.asia-teach.com/post-job/purchase-jobs">
              <GiTeacher />
              <span className="text">Post Job</span>
            </a>
          </li>
        </ul>
      </Nav>
    </WidthConstraint>
  </HeaderWrapper>
)

export default Header
